import Auth from '@aws-amplify/auth';
import { isMobile } from 'react-device-detect';
import { store } from 'core';

import { resetStoreAction } from 'core/actions';
import { routePaths } from 'modules/auth/constants';

export const logout = async () => {
  await Auth.signOut();

  const path = isMobile ? `${routePaths.root}?webview=mobile&authPage=signin` : routePaths.root;
  window.location.href = path;

  store.dispatch(resetStoreAction());
  window.localStorage.clear();
};
