import { AxiosError } from 'axios';

import { requestCache } from './cache';

import { logout } from 'core/utils/logout';

export const errorInterceptor = async (error: AxiosError) => {
  if (error.config) {
    const { baseURL, data, method, url, params } = error.config;

    if (requestCache.has(JSON.stringify({ baseURL, data, method, url, params }))) {
      requestCache.delete(JSON.stringify({ baseURL, data, method, url, params }));
    }
  }

  if (error.message === 'Cancel request') {
    return Promise.reject(error);
  }

  if (error.response === undefined) {
    return Promise.reject(error);
  }

  if (error.request === undefined) {
    return Promise.reject(error);
  }

  const {
    response: { status },
  } = error;

  if (status === 401) {
    logout();
  } else if (status >= 500) {
    console.log('error 500');
  }

  return Promise.reject(error);
};
